import axios from "axios";


export function SubscribeNewsletter(email: string) {
    axios.post('https://zkxi4wzi69.execute-api.ap-south-1.amazonaws.com/api/subscribe', {
        'email': email,
        withCredentials: false
    })
    .then(function (response) {
        if(response.status === 201){
            console.log(response.data)
        }
    })
    .catch(function (error) {
        console.log(error);
    });
}

export function SendUserQuery(data: object) {
    axios.post('https://zkxi4wzi69.execute-api.ap-south-1.amazonaws.com/api/query', data)
    .then(function (response) {
        if(response.status === 201){
            console.log(response.data)
        }
    })
    .catch(function (error) {
        console.log(error);
    });
}
