import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Chip, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grow, InputAdornment, MenuItem, MenuList, OutlinedInput, Popper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { DataCell, HeadCell, SortingTableProps, aligns } from '../config/props';
import { StyledSearch } from './StyledComponents';
import './css/SortingSearchTable.scss';


type Order = 'asc' | 'desc';

interface EnhancedTableToolbarProps {
  heading: string;
  numSelected: number;
  filterEvent?: string;
  onFilterEvent?: () => void;
}

interface EnhancedTableHeadProps {
  data: HeadCell[];
  numSelected: number;
  onRequestSort: (property: keyof DataCell) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  colWidths?: number[];
  colAligns?: aligns[];
}

interface EnhancedTableRowProps {
  headers: HeadCell[];
  row: DataCell;
  selected: string[];
  onSelect: React.Dispatch<React.SetStateAction<string[]>>;
  colWidths?: string[];
  colAligns?: aligns[];
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, heading, filterEvent, onFilterEvent } = props;

  return (
    <Toolbar className='toolbar-root'>
      <Box display={{ xs: 'block', md: 'flex' }} className='content-box'>
        <Box display={{ xs: 'block', sm: 'flex' }} alignItems={'center'} alignSelf={'center'}>
          <Typography variant="h5" className='toolbar-heading'>{heading}</Typography>
          <StyledSearch
            sx={{ marginTop: { xs: 2, sm: 0 } }}
            value={filterEvent}
            onChange={onFilterEvent}
            placeholder="Search event..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#fefefe', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'left'} mt={{ xs: 2, md: 0 }}>
          <Typography variant="subtitle1" sx={{ visibility: numSelected > 0 ? 'visible' : 'hidden' }}>{numSelected} selected</Typography>
          <Tooltip title="Delete" sx={{ visibility: numSelected > 0 ? 'visible' : 'hidden' }}>
            <IconButton onClick={() => console.log("Delete Events")}>
              <DeleteIcon sx={{ color: '#fefefe' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Toolbar>
  );
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { data, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, colAligns, colWidths } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ width: '4%' }}>
          <Checkbox
            color="info"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick} />
        </TableCell>
        {data.map((headCell: HeadCell, idx: number) => (
          <TableCell
            className='header-title'
            key={idx}
            align={colAligns ? colAligns[idx] : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={colWidths ? colWidths[idx] : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onRequestSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableRow(props: EnhancedTableRowProps) {

  const { headers, row, selected, onSelect, colAligns, colWidths } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<SVGSVGElement>(null);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const isSelected = (eid: string) => selected.indexOf(eid) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, eid: string) => {
    let newSelected: string[] = [];
    const selectedIndex = selected.indexOf(eid);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, eid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    onSelect(newSelected);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  }

  const deleteDialog = (
    <Dialog
      open={openDelete}
      onClose={handleDeleteClose}
    >
      <DialogTitle id="alert-dialog-title">
        {"Delete Event permanently?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you delete this event, you will loose all it's applications and their details. This action is not reversable.
          Are you sure you want to delete this event?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' disableElevation onClick={handleDeleteClose}>Cancel</Button>
        <Button onClick={handleDeleteClose} autoFocus>
          Yes, Delete
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <TableRow hover key={row.eid} selected={isSelected(row.eid)} id={`evnt-${row.eid}`}>
      <TableCell padding="checkbox">
        <Checkbox
          color="info"
          checked={isSelected(row.eid)}
          onClick={(event) => handleClick(event, row.eid)}
        />
      </TableCell>
      {headers.map((key, idx) => {
        if (key.id === 'status') {
          return (
            <TableCell key={idx} width={colWidths ? colWidths[idx] : undefined} align={colAligns ? colAligns[idx] : 'left'}>
              <Chip label={row.status} />
            </TableCell>
          )
        }
        else if (key.id === 'total') {
          return (
            <TableCell key={idx} width={colWidths ? colWidths[idx] : undefined} align={colAligns ? colAligns[idx] : 'left'}>
              {row.total - row.filled} of {row.total}
            </TableCell>
          )
        }

        return <TableCell key={idx} width={colWidths ? colWidths[idx] : undefined} align={colAligns ? colAligns[idx] : 'left'}>{row[key.id]}</TableCell>
      })}

      <TableCell align="center" sx={{ width: '4%' }}>
        <IconButton onClick={() => console.log("Handle More Menu")}>
          <MoreVertIcon ref={anchorRef} id="more-button" onClick={handleToggle} />
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            transition
            disablePortal
            sx={{ zIndex: 10 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-end' ? 'right top' : 'right bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                    >
                      <MenuItem onClick={handleClose}>Event Details</MenuItem>
                      <MenuItem onClick={handleClose}>Pause Applications</MenuItem>
                      <MenuItem onClick={handleClose}>Finish Event</MenuItem>
                      <Divider />
                      <MenuItem onClick={() => setOpenDelete(true)}>Delete</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </IconButton>
        {deleteDialog}
      </TableCell>
    </TableRow>
  )
}


export default function EnhancedTable(props: SortingTableProps) {

  const { heading, headers, rows, columnWidths, alignmets } = props;
  const [selected, setSelected] = React.useState<string[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof DataCell>('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof DataCell>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (property: keyof DataCell) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((evnt) => evnt.eid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      rows.slice().sort(getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <Box className='search-table-root'>
      <EnhancedTableToolbar numSelected={selected.length} heading={heading ?? ''} />
      <TableContainer className='table-container'>
        <Table stickyHeader sx={{ minWidth: 900 }}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            data={headers}
            colAligns={alignmets}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              return <EnhancedTableRow key={index} headers={headers} row={row} selected={selected} onSelect={setSelected} colWidths={columnWidths} colAligns={alignmets} />
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 70 * emptyRows,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
      className='table-footer-root'
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
