import axios from '../../config/axios';
import { ServerResponse } from '../../config/props';
import { setLocalStorage } from '../../utils/utils';


export const createUser = async (name: string, email: string, password: string, category: string) => {

  let payload = {
    "name": name,
    "email": email,
    "password": password,
    "password2": password,
    "category": category
  }

  const data = await axios.post<ServerResponse>('user/register/', payload)
    .then((response) => {
      if (response.status === 201 && response.data.error === 'EX00000') {
        return { status: 200, message: response.data.details }
      }
      return { status: 400, message: "Server responded with unexpected status." }
    })
    .catch((err) => {
      try {
        if (err.response.status === 400 && err.response.data.error === 'EX00001') {
          return { status: 400, message: getSerialErrors(err.response.data.details) }
        } else if (err.response.status === 400 && err.response.data.error === 'EX00010') {
          return { status: 400, message: "Failed to create user due to error(s): " + err.response.data.details }
        }
      } catch (e) {
        console.log(e);
      }
      return { status: 400, message: "Failed to create user because of Internal Server Error" }
    })

  return data;
}

export const authenticateUser = async (email: string, password: string) => {

  let payload = {
    "user_id": email,
    "password": password,
  }

  const data = await axios.post<ServerResponse>('user/login/', payload)
    .then((response) => {
      if (response.status === 200 && response.data.error === 'EX00000') {
        setLocalStorage('authToken', response.data.accesstoken ?? '');
        return { status: 200, message: "Login Successfull."}
      } else {
        return { status: 400, message: "Server responded with unexpected status."}
      }
    })
    .catch((err) => {
      try {
        if (err.response.status === 401 && err.response.data.error === 'EX00011') {
          return { status: 400, message: "Invalid Credentials"}
        } else if (err.response.status === 400 && err.response.data.error === 'EX00001') {
          return { status: 400, message: getSerialErrors(err.response.data.details)}
        } else if (err.response.status === 500 && err.response.data.error === 'EX00100') {
          return { status: 400, message: "User Not found. Please sign up."}
        }
      } catch (e) {
        console.log(e);
      }
      return { status: 400, message: "Failed to create user because of Internal Server Error."}
    })

  return data;
}


function getSerialErrors(obj: { [key: string]: any }) {
  let x = obj ?? { 'error': [] }
  return "Failed to create user due to error(s): " + Object.keys(x).reduce((a, b, i) => `${a}${i ? ' <-> ' : ''}${b}:${x[b]}`, '')
}
