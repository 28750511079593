import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import CustomNavigationBar from '../components/CustomAppBar';
import './css/Dashboard.scss';
import InfoCard from '../components/InfoCard';
import ApplicantsBrief from '../components/ApplicantBrief';

// sx={{backgroundImage: `url(${job_banner})`}}


export default function EventsDashboard() {


  let applicants = [...Array(12)].map((_, index) => ({
    title: "CANDIDATE",
  }));

  return (
    <Box className="event-dashboard">
      <CustomNavigationBar />
      <br />
      <Container disableGutters maxWidth='xl' className="dashboard-root">
        <Typography variant="h2" className='heading'>Dashboard</Typography>

        <Grid container spacing={3}>
          <Grid item xs={7} sm={6} lg={3} xl={2.5}>
            <InfoCard title={"My Event(s)"} value={10}/>
          </Grid>

          <Grid item xs={5} sm={6} lg={3} xl={2.5}>
          <InfoCard title={"Applicant(s)"} value={10}/>
          </Grid>

          <Grid item xs={7} sm={6} lg={3} xl={2.5}>
          <InfoCard title={"Pending Payment(s)"} value={10}/>
          </Grid>

          <Grid item xs={5} sm={6} lg={3} xl={2.5}>
          <InfoCard title={"Checklist(s)"} value={0}/>
          </Grid>
        </Grid>

        <Typography variant="h3" className='subheading'>Recent Applicants</Typography>

        <Grid container spacing={4} position={'inherit'} sx={{mb: 5}}>
          {applicants.map((applicant: any, index: any) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <ApplicantsBrief key={applicant.id} candidate={applicant} index={index} />
            </Grid>
          ))}
        </Grid>

      </Container>
    </Box>
  )
}
