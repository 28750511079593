import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';
import PlaceIcon from '@mui/icons-material/Place';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Avatar, Box, Chip, Paper, Stack, Typography } from '@mui/material';
import './css/EventBrief.scss';

export default function EventBrief() {

  let tags = ['Company Rating', 'Urgency', 'Verified', 'Company Size']

  return (
    <Paper elevation={2} className='event-card-root' sx={{ display: { xs: 'block', md: 'flex' }, borderRadius: 2 }}>
      <Stack direction='row' sx={{ borderRight: { md: '1px solid #dd1177' } }}>
        <Avatar
          alt="Company Logo"
          src="img_src"
          sx={{ width: 56, height: 56, m: 1, display: { xs: 'none', sm: 'flex' } }}
        />
        <Box sx={{ mx: { xs: 1, sm: 3 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h6' className="event-title">{"Company"} - {"Event Title"}</Typography>
            <Paper elevation={0} variant='elevation' className='event-badge'>
              <TrendingUpIcon fontSize='small' />
              <Typography variant='body2' sx={{ mx: '4px' }} fontSize={12} fontWeight={400}>Promoted</Typography>
            </Paper>
          </Box>
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <PlaceIcon sx={{ fontSize: '16px', color: '#dd1177' }} />
            <Typography variant='body2' className='event-location'>Delhi, Delhi</Typography>
          </Stack>
          <Stack direction='row' alignItems={'center'} sx={{ mt: 1 }}>
            <DateRangeIcon sx={{ fontSize: '16px', color: '#dd1177', mt: '-2px' }} />
            <Typography className='event-date'>Fri 13 Jan 2023 - Sun 22 Jan 2023</Typography>
          </Stack>
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <PaidIcon sx={{ fontSize: '16px', color: '#dd1177' }} />
            <Typography variant='body2' className='event-location'>5000 / day</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 1 }} className='event-tags'>
            {tags.map(element => {
              return <Chip key={element} label={element} size='small' variant='outlined' sx={{ mr: 1, color: '#d3d3d3', borderColor: '#dd1177' }} />
            })}
          </Stack>
          <Typography variant='body2' className='company-desc'>{"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut commodi illo perferendis, reiciendis dolorum quae culpa voluptate distinctio minima. Officiis nam dolore impedit placeat temporibus? Vero tempore iusto cumque voluptas?".substring(0, 150)}...</Typography>
        </Box>
      </Stack>
      <Stack spacing={2} className='event-card-actions' direction={'column'}>
        <Box sx={{width: '100%'}}>
          <Paper elevation={0} className='view-event'>View Details</Paper>
          <Paper elevation={0} className='save-event'>Save for Later</Paper>
          <Paper elevation={0} className='hide-event'>Hide</Paper>
        </Box>
      </Stack>
    </Paper>
  )
}
