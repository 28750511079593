export function setLocalStorage(key:string, value:any, ttl:number = 60000) {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}


export function getLocalStorage(key: string) {
	const itemStr = localStorage.getItem(key)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

export function getChipColor(status: string): string {
	let colorcodes: { [key: string]: string } = {
	  'rejected': '#ff6746ff',
	  'applied': '#000000020',
	  'shortlisted': '#1976d2ff',
	  'selected': '#1bd084ff'
	}
	return colorcodes[status];
  }
