
import { Box, Button, Container, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import './css/EventsList.scss';

import EnhancedTable from '../components/SortingSeachTable';
import { DataCell, HeadCell, aligns } from '../config/props';
import { Fragment, useState } from 'react';
import CustomNavigationBar from '../components/CustomAppBar';
import CreateEvent from '../components/EventModal';


// interface EnhancedTableProps {
//   numSelected: number;
//   onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
//   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   order: Order;
//   orderBy: string;
//   rowCount: number;
// }


const colAlign: aligns[] = ['left', 'left', 'center', 'center', 'center'];
const colLengths = ['10%', '30%', '15%', '15%', '15%'];

const headCells: HeadCell[] = [
  {
    id: 'date',
    label: 'Created On',
  },
  {
    id: 'name',
    label: 'Event Name',
  },
  {
    id: 'applicants',
    label: 'Total Applicant(s)',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'total',
    label: 'Position(s)',
  },
];

const data: DataCell[] = [
  {
    eid: 'eid1',
    date: 'Date',
    name: 'Name',
    applicants: 25,
    status: 'status',
    filled: 5,
    total: 10,
  },
  {
    eid: 'eid2',
    date: 'Date',
    name: 'Name',
    applicants: 25,
    status: 'status',
    filled: 5,
    total: 10,
  },
  {
    eid: 'eid3',
    date: 'Date',
    name: 'Name',
    applicants: 25,
    status: 'status',
    filled: 5,
    total: 10,
  },
  {
    eid: 'eid4',
    date: 'Date',
    name: 'Name',
    applicants: 25,
    status: 'status',
    filled: 5,
    total: 10,
  },
  {
    eid: 'eid5',
    date: 'Date',
    name: 'Name',
    applicants: 25,
    status: 'status',
    filled: 5,
    total: 10,
  },
];


export function ManageEvents() {

  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleCloseModal() {
    setOpenModal(false);
  }

  const handleEventModal = () => {
    setOpenModal(true);
  }

  return (
    <Fragment>
      <CustomNavigationBar />
      <Box className="manage-events">
      <Container disableGutters maxWidth='xl'>
        <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h2" className='title'>Manage Events</Typography>
          <Button variant="contained" className='new-event-btn' disableElevation size='large' onClick={handleEventModal}>New Event</Button>
          <CreateEvent openModal={openModal} onCloseModal={handleCloseModal} />
        </Stack>
        <br/>
        <EnhancedTable heading={"Active Events"} headers={headCells} rows={data} columnWidths={colLengths} alignmets={colAlign} />

        <EnhancedTable heading={"Finished Events"} headers={headCells} rows={data} />

        <EnhancedTable heading={"Drafts"} headers={headCells} rows={data} />
      </Container>
      </Box>
    </Fragment>
  );
}
