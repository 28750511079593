import React, { SetStateAction, useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, IconButton, InputAdornment, InputBase, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { authenticateUser, createUser } from "../apis/Server/auth";
import FrostedNavigationBar from "../components/DefaultAppBar";
import { UserCategorySwitch } from "../components/StyledComponents";
import { DefaultErrors } from "../config/props";
import backdrop from './../assets/images/signin_bg.webp';
import './css/Authentication.scss';


export default function AuthenticationPage() {

  const [loginPage, setLoginPage] = useState<boolean>(true);

  const nav_btn = (
    <Button key={loginPage ? 'sign-up' : 'sign-in'}
      variant='contained'
      onClick={() => setLoginPage(!loginPage)}
      sx={{
        display: { xs: 'none', md: 'block' },
        color: 'inherit',
        mx: 2,
        borderRadius: 10,
        backgroundImage: '-webkit-linear-gradient(120deg, #505add 10%, #dd1177 100%) !important;'
      }}>
      {loginPage ? 'Sign Up' : 'Sign In'}
    </Button>
  )

  return (
    <Box>
      <FrostedNavigationBar btn={nav_btn} />
      <Box className="auth-root">
        <Box className="img-container" display={{ xs: "none", md: "block" }}>
          <Box className="bg-img"
            sx={{
              backgroundImage: `url(${backdrop})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
          >
            <Typography variant="subtitle1" fontWeight='medium' color='white' textAlign='center' sx={{ mb: 1, letterSpacing: "8px" }}>Never Settle</Typography>
            <Typography variant="h3" fontWeight='bold' color='white' textAlign='center' sx={{ letterSpacing: "8px" }}>Every Event Is Special</Typography>
          </Box>
        </Box>
          {loginPage ?
            <RenderLoginPage isLogin={setLoginPage} />
            :
            <RenderSignUpPage isLogin={setLoginPage} />
          }
      </Box>
    </Box>
  );
}


function RenderLoginPage(props: { isLogin: React.Dispatch<SetStateAction<boolean>> }) {

  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<DefaultErrors>({});

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState(true);


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const loginUser = () => {
    authenticateUser(userEmail, password).then((resp) => {
      if (resp.status === 200) {
        navigate('/events');
      } else {
        alert(resp.message);
      }
    })
  }

  useEffect(() => {
    let errs = { ...errors };

    let emailrex = /^([A-Z|a-z|0-9](.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((.){0,1}[A-Z|a-z|0-9]){2}.[a-z]{2,3}$/;
    if ((emailrex.test(userEmail) && userEmail.length < 50) || userEmail.length === 0) {
      delete errs['email'];
    } else {
      errs['email'] = "Please enter a valid email.";
    }

    if ((password.length > 5 && password.length < 20) || password.length === 0) {
      delete errs['password'];
    } else {
      errs['password'] = "Password should be between 6 to 20 characters";
    }
    // update references with errors
    setErrors(errs);

  }, [password, userEmail]);

  return (
    <Box className="sign-in-form">
      <Box className='form-root'>
        <Box>
          <Typography variant='h4' className="form-heading">Good to see you again!</Typography>
          <Typography variant='subtitle1' className="form-sub-heading">Enter your email and password to sign in</Typography>
        </Box>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Stack direction='column'>
            <Typography component="label" className='form-field'>Email</Typography>
            <InputBase type="email" placeholder="example@company.com" className='form-input' autoComplete={"username"} value={userEmail} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEmail(event.target.value)} />
          </Stack>
          <Stack direction='column'>
            <Typography component="label" className='form-field'>Password</Typography>
            <InputBase
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              className='form-input'
              placeholder="Your Password...."
              type={showPassword ? 'text' : 'password'}
              autoComplete={"current-password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    sx={{ color: 'gray' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {/* <Typography component="label" className='helper-text'>Forgot Password?</Typography> */}
          </Stack>
          {/* <Stack direction='row' justifyContent='left' alignItems='center' mt={2} ml={1}>
            <AntSwitch color="info" size="small" checked={rememberMe} onChange={handleSetRememberMe} />
            <Typography fontFamily='inherit' color="inherit">&nbsp;&nbsp;Remember me</Typography>
          </Stack> */}
          <Button variant='text' className='login-btn' onClick={loginUser}>Sign In</Button>
          <Box className='signup-btn'>
            <Typography fontSize='14px' fontFamily='inherit'>Don&apos;t have an account?&nbsp;&nbsp;</Typography>
            <Typography variant="button" fontSize='14px' fontFamily='inherit' fontWeight='600' sx={{ color: '#fefefe', textDecoration: 'none', cursor: 'pointer' }} onClick={() => props.isLogin(false)}>Sign Up</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}


function RenderSignUpPage(props: { isLogin: React.Dispatch<SetStateAction<boolean>> }) {

  const navigate = useNavigate();

  const [fullName, setFullName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [category, setCategory] = useState<string>('applicant');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<DefaultErrors>({});
  const [disableContinue, setDisableContinue] = useState<boolean>(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setCategory('recruiter') : setCategory('applicant');
  }

  const registerNewUser = () => {
    createUser(fullName, userEmail, password, category).then((resp) => {
      alert(resp.message);
      if (resp.status === 200) {
        authenticateUser(userEmail, password).then((data) => {
          if (data.status === 200) {
            navigate('/events');
          } else {
            alert(data.message);
          }
        });
      }
    }).catch((err) => {
      alert(err.message);
    })
  }

  useEffect(() => {
    if (fullName !== '' && userEmail !== '' && password !== '' && Object.keys(errors).length === 0) {
      setDisableContinue(false);
    } else {
      setDisableContinue(true);
    }
  }, [errors])


  useEffect(() => {
    let errs = { ...errors };
    if ((fullName.length > 3 && fullName.length < 50) || fullName.length === 0) {
      delete errs['name'];
    } else {
      errs['name'] = "Enter your full name";
    }

    let emailrex = /^([A-Z|a-z|0-9](.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((.){0,1}[A-Z|a-z|0-9]){2}.[a-z]{2,3}$/;
    if ((emailrex.test(userEmail) && userEmail.length < 50) || userEmail.length === 0) {
      delete errs['email'];
    } else {
      errs['email'] = "Please enter a valid email.";
    }

    if ((password.length > 5 && password.length < 20) || password.length === 0) {
      delete errs['password'];
    } else {
      errs['password'] = "Password should be between 6 to 20 characters";
    }
    // update references with errors
    setErrors(errs);

  }, [fullName, password, userEmail, category]);


  return (
    <Box className="sign-up-form">
      <Box className='form-root'>
        <Box>
          <Typography variant='h4' className="form-heading">Let&apos;s get started.</Typography>
          <Typography variant='subtitle1' className="form-sub-heading">Begin the adventure by filling the form below.</Typography>
        </Box>

        <Box marginTop={4}>
          {Object.keys(errors).map((key) => {
            return <Typography key={key} className="error-line">&#10006; {errors[key]}</Typography>
          })}
        </Box>

        <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Stack direction='column'>
            <Typography component="label" className='form-field'>Name</Typography>
            <InputBase type="text" className='form-input' placeholder="Full Name..." autoComplete={"name"} value={fullName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFullName(event.target.value)} />
          </Stack>
          <Stack direction='column'>
            <Typography component="label" className='form-field'>Email</Typography>
            <InputBase type="email" placeholder="Your Email...." className='form-input' autoComplete={"username"} value={userEmail} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEmail(event.target.value)} />
          </Stack>
          <Stack direction='column'>
            <Typography component="label" className='form-field' >Password</Typography>
            <InputBase
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              className='form-input'
              placeholder="Your Password...."
              type={showPassword ? 'text' : 'password'}
              autoComplete={"new-password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    sx={{ color: 'gray' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent={"center"} mt={4}>
            <Typography>Applicant</Typography>
            <UserCategorySwitch checked={category === 'applicant' ? false : true} defaultChecked={false} onChange={handleCategoryChange} />
            <Typography>Recruiter</Typography>
          </Stack>
          <Button variant='text' className='login-btn' disabled={disableContinue} onClick={registerNewUser}>Register</Button>
          <Box className='signup-btn'>
            <Typography fontSize='14px' fontFamily='inherit'>Already have an account?&nbsp;&nbsp;</Typography>
            <Typography variant="button" fontSize='14px' fontFamily='inherit' fontWeight='600' sx={{ color: '#fefefe', textDecoration: 'none', cursor: 'pointer' }} onClick={() => props.isLogin(true)}>Log In</Typography>
          </Box>
          <Typography variant="subtitle1" className='sign-up-text'>By creating an account, you agree to the <a href="https://www.termsofservicegenerator.net/live.php?token=495MYRzWFfWMB0qfuyQj45zxJJXo1YBT">Terms of Service</a>. For more information about privacy practices, see the <a href="https://www.privacypolicygenerator.info/live.php?token=ooGtHJazsVJMpyr09rXTtxY1HkpRzxZ0">Privacy Statement.</a></Typography>
        </Box>
      </Box>
    </Box>
  )
}
