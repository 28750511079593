import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Fragment, useState } from 'react';
import { SendUserQuery } from '../apis/Serverless/AWS';
import './css/Footer.scss';

export default function EventsFooter() {

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [msg, setMsg] = useState<string>("");

  const [err, setErr] = useState<string>("");

  const handleSubmit = () => {
    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(validRegex) && name.length > 3 && subject.length > 3 && msg?.length > 3) {

      SendUserQuery({ 'name': name, 'email': email, 'subject': subject, 'message': msg })

      setErr('');
      setName('');
      setEmail('');
      setSubject('');
      setMsg('Sent Successfully!.');
    }
    else {
      setErr('Please enter valid details.');
    }
  }

  return (
    <Fragment>
      <Container maxWidth='xl' disableGutters className='footer-root'>
        <Typography variant='h5' className="footer-subtitle">Have a question?</Typography>
        <Typography variant='h2' className="footer-title">Reach out for a new project or just say hello</Typography>
        <Stack className='card-group' direction={{ sm: 'column', md: 'row' }}>
          <Box className='info-card translucent'>
            <Typography variant='body2' className='card-heading'>SEND US A MESSAGE</Typography>
            <Stack className='form-group' spacing={5}>
              <TextField fullWidth placeholder="Name" variant="standard" value={name} onChange={(event) => setName(event.target.value)} />
              <TextField fullWidth placeholder="Email" variant="standard" value={email} onChange={(event) => setEmail(event.target.value)} />
              <TextField fullWidth placeholder="Subject" variant="standard" value={subject} onChange={(event) => setSubject(event.target.value)} />
              <TextField fullWidth placeholder="Message" variant="standard" multiline rows={4} value={msg} onChange={(event) => setMsg(event.target.value)} />
              <Button variant='contained' fullWidth className='submit-form' onClick={handleSubmit}>Submit</Button>
              <Typography variant="body2">{err}</Typography>
            </Stack>
          </Box>
          <Box className='info-card' >
            <Typography variant='body2' className='card-heading'>CONTACT INFO</Typography>
            <Stack className='form-group' spacing={5}>
              <Box>
                <Typography className='card-subheading'>Reach Out to Us</Typography>
                <Typography className='card-text'>New Delhi, India,<br /> Earth, Milky Way Galaxy</Typography>
              </Box>
              <Box>
                <Typography className='card-subheading'>Email Us At</Typography>
                <Typography className='card-text'>mahesh@leadmycrew.com</Typography>
              </Box>
              <Box>
                <Typography className='card-subheading'>Call Us At</Typography>
                <Typography>(+91) 87008 62798</Typography>
              </Box>
              <Box>
                <Typography className='card-subheading'>Follow Us On</Typography>
                <Stack spacing={2} direction='row'>
                  <LinkedInIcon className='social-media-icon' />
                  <FacebookIcon className='social-media-icon' />
                  <TwitterIcon className='social-media-icon' />
                  <InstagramIcon className='social-media-icon' />
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Fragment>
  )
}
