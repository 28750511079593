import { createTheme, PaletteMode, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { basic_theme, ColorModeContext, getDesignTokens } from '../config/theme';
import { ManageCandidates } from './ApplicationsList';
import AuthenticationPage from './Authentication';
import './css/App.scss';
import EventsDashboard from './Dashboard';
import EventsHomepage from './EventsHomepage';
import { ManageEvents } from './EventsList';
import LandingPage from './LandingPage';


function App() {

  const [mode, setMode] = React.useState<PaletteMode>('light');
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  return (
    <div className="App">
      <ThemeProvider theme={basic_theme}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<AuthenticationPage />} />
          <Route path="/events/" element={<EventsHomepage />} />
          <Route path="/events/manage/" element={<ManageEvents />} />
          <Route path="/events/:eventid/applications/" element={<ManageCandidates />} />
          <Route path="/dashboard/" element={<EventsDashboard />} />
          <Route path="/:companyid/events/" element={<div/>} />
        </Routes>
      </ThemeProvider>

      {/* <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
        <EventHomepage />
        </ThemeProvider>
      </ColorModeContext.Provider> */}
    </div>
  );

}

export default App;
