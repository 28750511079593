import { useCallback, useState } from "react";
import { Container, Engine } from "tsparticles-engine";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export const ParticlesBackground = () => {

  const particlesInit = useCallback(async (engine: Engine) => {
    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
  }, []);

  return (

    <Particles
      id="tsparticles"
      canvasClassName="particles-container"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        "fullScreen": {
          "enable": false,
        },
        "fpsLimit": 120,
        "particles": {
          "number": {
            "value": 20,
            "density": {
              "enable": true,
              "value_area": 700
            }
          },
          "color": {
            "value": "#ff0000",
            "animation": {
              "enable": true,
              "speed": 10,
              "sync": true
            }
          },
          "opacity": {
            "value": 0.8
          },
          "size": {
            "value": {
              "min": 0.1,
              "max": 5
            }
          },
          "links": {
            "enable": false,
            "distance": 100,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 1.5,
            "direction": "none",
            "outModes": {
              "default": "out"
            }
          }
        },
        "interactivity": {
          "events": {
            "onHover": {
              "enable": true,
              "mode": "grab"
            },
            "onClick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "repulse": {
              "distance": 100
            },
            "push": {
              "quantity": 2
            },
            "bubble": {
              "distance": 400,
              "duration": 2,
              "opacity": 0.8,
              "size": 40,
              "speed": 3
            },
            "connect": {
              "distance": 80,
              "lineLinked": {
                "opacity": 0.5
              },
              "radius": 60
            },
            "grab": {
              "distance": 200,
              "lineLinked": {
                "opacity": 1
              }
            },
          }
        },
        "detectRetina": true
      }}
    />
  );
};
