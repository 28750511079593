import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import './css/AppBar.css'
import { NavigationBarProps } from '../config/props';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../slices/hooks';
import { setSection } from '../slices/appSlice';
import leadmycrew_logo from './../assets/logos/logo.png';



export default function FrostedNavigationBar(props: NavigationBarProps) {

  const { btn, disabled } = props

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  var btn_props = null;


  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handlePageSelection(section: string){
    dispatch(setSection(section));
    navigate('/');
  }

  if (btn) {
    btn_props = Object.assign({ disabled: disabled }, btn.props);
  }

  return (
    <Box className='navbar-root'>
      <Box className='company_logo'>
        {/* <Typography variant='h4' fontFamily={'inherit'} fontWeight={200}>leadmycrew.</Typography> */}
        <picture>
              <source srcSet={leadmycrew_logo} type="image/png" />
              <img alt="leadmycrew Logo" style={{ maxWidth: '16em' }} />
            </picture>
      </Box>

      <Box sx={{ display: { xs: 'none', lg: 'block' } }} className="pages">
        {/* #TODO: change to redux-toolkit */}
        <Button key='home' onClick={() => handlePageSelection('home-root')} sx={{ color: 'inherit', mx: 2 }}>Home</Button>
        <Button key='about-us' onClick={() => handlePageSelection('about-root')} sx={{ color: 'inherit', mx: 2 }}>About Us</Button>
        <Button key='support' onClick={() => handlePageSelection('footer-root')} sx={{ color: 'inherit', mx: 2 }}>Support</Button>
      </Box>


      {btn ?
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <Button {...btn_props}>{btn.props.children}</Button>
        </Box>
        :
        null
      }


      <Box sx={{ display: { xs: 'flex', lg: 'none' } }} className="menu-mobile">
        <IconButton
          size="large"
          onClick={handleOpenMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          sx={{ display: { xs: 'block', lg: 'none' } }}
          id="menu-appbar"
          variant='menu'
          anchorEl={anchorEl}
          open={anchorEl ? true : false}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem key='home' onClick={() => handlePageSelection('home-root')}>
            <Typography textAlign="center">Home</Typography>
          </MenuItem>
          <MenuItem key='about-us' onClick={() => handlePageSelection('about-root')}>
            <Typography textAlign="center">About Us</Typography>
          </MenuItem>
          <MenuItem key='support' onClick={() => handlePageSelection('footer-root')}>
            <Typography textAlign="center">Support</Typography>
          </MenuItem>
          {btn ?
            <MenuItem disabled={disabled ?? false} key={btn.key} onClick={btn.props.onClick}>
              <Typography textAlign="center">{btn.props.children}</Typography>
            </MenuItem>
            :
            null
          }
        </Menu>
      </Box>
    </Box>
  );
}
