import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { Container } from '@mui/system';
import { useScrollTrigger } from '@mui/material';
import leadmycrew_logo from './../assets/logos/logo.png';

import './css/AppBarCustom.css'


const user = ["Saved Events", "Activity", "Portfolio", "Contact"];
const admin = ["All Events", "Dashboard", "Company Profile"]
//[TODO]: based on user, change navigation buttons
let pages = admin
const settings = ['General Settings', 'My Applications', 'Help Center', 'Logout'];

export default function CustomNavigationBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuEl, setMenuEl] = React.useState<null | String>(null);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10
  });

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, anchor: String) => {
    setAnchorEl(event.currentTarget);
    setMenuEl(anchor);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuEl(null);
  };

  return (
    <React.Fragment>
      <Box position="fixed" className={trigger ? "default-navbar-elevated" : "default-navbar"}>
        <Container disableGutters className="custom-navbar-root" maxWidth={'xl'}>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }} className="menu-mobile">
            <IconButton
              size="large"
              onClick={(e) => handleOpenMenu(e, 'pages')}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="appbar-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={menuEl === 'pages'}
              onClose={handleCloseMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              variant='menu'
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box className='logo-container'>
            <picture>
              <source srcSet={leadmycrew_logo} type="image/png" />
              <img alt="leadmycrew Logo" style={{ maxWidth: '12em' }} />
            </picture>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }} className="page-navigator">
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseMenu}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box className="profile-navigator">
            <Tooltip title="Open settings" className='profile-avatar'>
              <IconButton onClick={(e) => handleOpenMenu(e, 'profile')} sx={{ p: 0 }}>
                <Avatar alt="Avatar" src="/x.jpg" />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              id="appbar-menu"
              open={menuEl === 'profile'}
              onClose={handleCloseMenu}
              onClick={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.22))',
                  mt: 1.5,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              keepMounted
              variant='menu'
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseMenu}>
                  {setting}
                </MenuItem>
              ))}
            </Menu>
          </Box>

        </Container>
      </Box>
    </React.Fragment>
  );
};
