import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Checkbox, Chip, ClickAwayListener, Container, Grow, MenuItem, MenuList, Popper, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import './css/EventsList.scss';

import SortingTable from '../components/SortingSeachTable';
import { ApplicantRowProps, DataCell, HeadCell, aligns } from '../config/props';
import { getChipColor } from '../utils/utils';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 14,
    },
  }));


  function createData(
    aptid: string,
    date: string,
    name: string,
    age: string,
    type: string,
    position: string,
    status: string,
  ) {
    return {
      aptid,
      date,
      name,
      age,
      type,
      position,
      status,
      details: [
        {
          date: '2020-01-05',
          customerId: '11091700',
          amount: 3,
        },
        {
          date: '2020-01-02',
          customerId: 'Anonymous',
          amount: 1,
        },
      ],
    };
  }

  const rows = [
    createData("1", "1/1/2023", "Name Jhon Doe 1", "24", "Engineer", "Event Manager", "applied"),
    createData("2", "1/1/2023", "Name Jhon Doe 2", "24", "Engineer", "Event Manager", "applied"),
    createData("3", "1/1/2023", "Name Jhon Doe 3", "24", "Engineer", "Event Manager", "shortlisted"),
    createData("4", "1/1/2023", "Name Jhon Doe 4", "24", "Engineer", "Event Manager", "applied"),
    createData("15", "1/1/2023", "Name Jhon Doe 5", "24", "Engineer", "Event Manager", "applied"),
    createData("6", "1/1/2023", "Name Jhon Doe 6", "24", "Engineer", "Event Manager", "applied"),
    createData("7", "1/1/2023", "Name Jhon Doe 7", "24", "Engineer", "Event Manager", "selected"),
    createData("8", "1/1/2023", "Name Jhon Doe 8", "24", "Engineer", "Event Manager", "applied"),
    createData("9", "1/1/2023", "Name Jhon Doe 9", "24", "Engineer", "Event Manager", "applied"),
    createData("12", "1/1/2023", "Name Jhon Doe 10", "24", "Engineer", "Event Manager", "rejected"),
    createData("13", "1/1/2023", "Name Jhon Doe 11", "24", "Engineer", "Event Manager", "applied"),
  ];


  export function ManageCandidates() {
    const [selected, setSelected] = useState<string[]>([]);

    let numSelected = 0
    let rowCount = 10

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.aptid);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    return (
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Applicants
          </Typography>
          <Button variant="contained" startIcon={<AddIcon />}>
            New Event
          </Button>
        </Stack>
        <TableContainer component={Paper}>
          <Table className='applicants-root'>
            <TableHead>
              <TableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="default"
                    sx={{ color: 'white' }}
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                  />
                </StyledTableCell>
                <StyledTableCell colSpan={1} align='left'>Applied On</StyledTableCell>
                <StyledTableCell colSpan={3} align='left'>Name</StyledTableCell>
                <StyledTableCell colSpan={1} align='center'>Age&nbsp;(yrs)</StyledTableCell>
                <StyledTableCell colSpan={1} align='center'>Gender</StyledTableCell>
                <StyledTableCell colSpan={2} align='left'>Position</StyledTableCell>
                <StyledTableCell colSpan={1} align='left'>Status</StyledTableCell>
                <StyledTableCell colSpan={3} align='center'>Contact</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.aptid} row={row} onSelect={setSelected} selected={selected} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }


  function Row(props: ApplicantRowProps) {
    const { row, selected, onSelect } = props;
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<SVGSVGElement>(null);

    const isSelected = (aptid: string) => selected.indexOf(aptid) !== -1;

    const handleClick = (event: any, aptid: string) => {
      let newSelected: string[] = [];
      const selectedIndex = selected.indexOf(aptid);

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, aptid);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      onSelect(newSelected);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }

      prevOpen.current = open;
    }, [open]);

    return (
      <Fragment>
        <TableRow hover key={row.aptid} selected={isSelected(row.aptid)}>
          <TableCell padding="checkbox" >
            <Checkbox color="default" checked={isSelected(row.aptid)} onClick={(event) => handleClick(event, row.aptid)} />
          </TableCell>
          <TableCell colSpan={1} align='left'>{row.date}</TableCell>
          <TableCell colSpan={3} align='left'>{row.name}</TableCell>
          <TableCell colSpan={1} align="center">{row.age}</TableCell>
          <TableCell colSpan={1} align="center">{row.type}</TableCell>
          <TableCell colSpan={2} align="left">{row.position}</TableCell>
          <TableCell colSpan={1} align="left">
            <Chip label={row.status} sx={{ bgcolor: getChipColor(row.status), color: row.status !== 'applied' ? 'white' : 'black', textTransform: 'uppercase' }} />
          </TableCell>
          <TableCell colSpan={3} align="center">
            <Stack direction={'row'} spacing={1} id={'action-btns'} justifyContent={'center'}>
              <IconButton onClick={() => console.log("Handle Selection")}>
                <DoneIcon />
              </IconButton>
              <IconButton onClick={() => console.log("Handle Rejection")}>
                <CloseIcon />
              </IconButton >
              <IconButton onClick={() => console.log("Handle More Menu")}>
                <MoreVertIcon ref={anchorRef} id="more-button" onClick={handleToggle} />
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  placement="bottom-end"
                  transition
                  disablePortal
                  sx={{ zIndex: 10 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                          >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>My account</MenuItem>
                            <MenuItem onClick={handleClose}>Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
