import { Avatar, Box, Chip, Paper, Stack, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PlaceIcon from '@mui/icons-material/Place';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';
import './css/InfoCard.scss';

export default function InfoCard(props: any) {

  return (
    <Paper elevation={0} className='info-card-root' >
        <Typography variant='h6' className="info-title">{props.title}</Typography>
        <Typography variant='h3' className='info-value'>{props.value}</Typography>
    </Paper>
  )
}
