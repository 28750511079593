import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface AppState {
  darkMode: boolean
  section: string
}

const initialState: AppState = {
  darkMode: false,
  section: ''
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {

    enableDarkMode(state, action: PayloadAction<boolean>) {
      state.darkMode = action.payload
    },

    setSection(state, action: PayloadAction<string>) {
      state.section = action.payload
    },

  }
})

export const { enableDarkMode, setSection } = appSlice.actions
export default appSlice.reducer
