import { PaletteMode } from '@mui/material';
import { amber, deepOrange, grey, blue, common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import React from 'react';


export const ColorModeContext = React.createContext({
  toggleColorMode: () => {
    // This is intentional
  },
});

export const basic_theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins, 'Roboto', sans-serif",
    },
  },
});

// #3d1d4d -> blackcurrant
// #505add -> sky blue
// #6f79bf -> blue
// #151851 -> dark blue
// #d3d3d3 -> grey
// #f1f1f1 -> lightgrey
// #dd1177 -> ruby
// #df42b1 -> another ruby/ pink
// #1d1e1f -> swamp
// #ffbbdd -> cotton candy
// #cc1c1c -> blood red
// #21aa12 -> goblin green
// #0e0e0e -> black
// #1d1e1f -> almost black
// #fefefe -> white
// #6aeea6 -> cyan

const palette = {
  light: {
    primary: {
      main: '#34C0AC',
      light: '#B1DED3',
      dark: '#00765A',
    },
  },
};

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: {
          main: palette.light.primary.main,
          light: palette.light.primary.light,
          dark: palette.light.primary.dark,
        },

        divider: amber[200],
        text: {
          primary: grey[900],
          secondary: grey[800],
        },
      }
      : {
        primary: deepOrange,
        divider: deepOrange[700],
        background: {
          default: deepOrange[900],
          paper: deepOrange[900],
        },
        text: {
          primary: '#fff',
          secondary: grey[500],
        },
      }),
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    body1: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
  },
});

export const getThemedComponents = (mode: PaletteMode) => ({
  components: {
    ...(mode === 'light'
      ? {
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: grey[800],
            },
          },
        },
        MuiLink: {
          variant: 'h3',
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 0,
              color: common.white,
              fontFamily:
                "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
              fontSize: 20,
              borderWidth: 2,
              '&:hover': {
                borderWidth: 2,
              },
            },
          },
          variants: [
            {
              props: { variant: 'contained' },
              style: {
                fontFamily:
                  "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
              },
            },
            {
              props: { variant: 'outlined' },
              style: {
                color: palette.light.primary.main,
              },
            },
            {
              props: { variant: 'primary', color: 'primary' },
              style: {
                border: '4px dashed blue',
              },
            },
          ],
        },
        MuiList: {
          styleOverrides: {
            root: {},
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              color: common.white,
              alignItems: 'stretch',
              fontFamily:
                "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              color: common.white,
              fontFamily:
                "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
            },
          },
        },
      }
      : {
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: blue[800],
            },
          },
        },
      }),
  },
});
