import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { Alert, Button, OutlinedInput, Snackbar, Switch, Typography } from '@mui/material';
import { SubscribeNewsletter } from '../apis/Serverless/AWS';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { alpha, styled } from '@mui/material/styles';


type CustomInputProps = {
  value: string | null;
  setValue: React.Dispatch<React.SetStateAction<string | null>>;
  successMsg: string | null;
  errMsg: string | null;
};


export function CustomizedInputBase(props: CustomInputProps) {

  const [error, setError] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);


  const checkValue = (event: any) => {

    props.setValue(event.target.value);

    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.value === '' || event.target.value.match(validRegex)) {
      setError('');
    }
    else {
      setError('Enter valid email address.');
    }
  }

  const handleSubmit = (email: string) => {
    SubscribeNewsletter(email);
    setOpen(true);
    props.setValue('');
  }


  return (
    <>
      <Paper
        variant="outlined"
        component="form"
        sx={{ mt: 4, display: 'flex', alignItems: 'center', width: '100%', maxWidth: 600, borderRadius: '5em', outline: '1.5px solid rgb(0,0,0, 0.12)', outlineOffset: -2 }}
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder="Enter your email"
          value={props.value}
          onChange={checkValue}
        />
        <Button
          disableElevation
          disabled={error ? true : false}
          variant='contained'
          color='success'
          sx={{
            backgroundColor: '#21aa12',
            borderRadius: '0em 5em 5em 0em',
            border: error ? '1px solid lightgray' : '1px solid #21aa12',
            fontSize: '12px !important',
            padding: '1em 2em'
          }}
          onClick={() => (props.value !== '' && error === '') ? handleSubmit(props.value!) : null}
        >
          Subscribe
        </Button>
      </Paper>
      <Typography fontSize={'12px'} color='red' height={'2em'}>{error}</Typography>
      <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
          {props.successMsg}
        </Alert>
      </Snackbar>
    </>
  );
}


export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 18,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(11px)',
      color: '#fefefe',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 15,
    height: 14,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 8,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255, .35)' : 'rgba(0,0,0, .25)',
    boxSizing: 'border-box',
  },
}));



// const iconString = ReactDOMServer.renderToString(<SupportAgentIcon />);

export const UserCategorySwitch = styled(Switch)(({ theme }) => ({
  width: 64,
  height: 36,
  padding: 10,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',

    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#fefefe',

      '& .MuiSwitch-thumb:before': {
        // backgroundImage: `url("data:image/svg+xml;${encodeURIComponent(renderToStaticMarkup(React.createElement(SupportAgentIcon)))}")`,
        // backgroundImage: `url("data:image/svg+xml;${encodeURIComponent(iconString)}")`,
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><g><g><path fill="rgb(255,255,255)" d="M21,12.22C21,6.73,16.74,3,12,3c-4.69,0-9,3.65-9,9.28C2.4,12.62,2,13.26,2,14v2c0,1.1,0.9,2,2,2h1v-6.1 c0-3.87,3.13-7,7-7s7,3.13,7,7V19h-8v2h8c1.1,0,2-0.9,2-2v-1.22c0.59-0.31,1-0.92,1-1.64v-2.3C22,13.14,21.59,12.53,21,12.22z"/><circle fill="rgb(255,255,255)" cx="9" cy="13" r="1"/><circle fill="rgb(255,255,255)" cx="15" cy="13" r="1"/><path fill="rgb(255,255,255)" d="M18,11.03C17.52,8.18,15.04,6,12.05,6c-3.03,0-6.29,2.51-6.03,6.45c2.47-1.01,4.33-3.21,4.86-5.89 C12.19,9.19,14.88,11,18,11.03z"/></g></g></svg>')`,
      },

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1d1e1f',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#1d1e1f',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><g><circle fill="rgb(255,255,255)" cx="12" cy="4" r="2"/><path fill="rgb(255,255,255)" d="M15.89,8.11C15.5,7.72,14.83,7,13.53,7c-0.21,0-1.42,0-2.54,0C8.24,6.99,6,4.75,6,2H4c0,3.16,2.11,5.84,5,6.71V22h2v-6h2 v6h2V10.05L18.95,14l1.41-1.41L15.89,8.11z"/></g></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: "#1d1e1f",
    borderRadius: '1em',
  },
}));


export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 250,
  height: 40,
  color: theme.palette.common.white,
  marginTop: (window.innerWidth ?? 0) > 600 ? 0 : 8,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    // width: 350,
    // boxShadow: theme.shadows[2],
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.common.white, 0.32)} !important`,
  },
}));
