import { Avatar, Box, Chip, Paper, Stack, Typography, Link, Card, Button, ButtonGroup, Divider } from '@mui/material';
import backdrop from './../assets/images/signin_bg.webp';
import shape_svg from './../assets/svg/shape-avatar.svg';
import './css/ApplicantBrief.scss';


export default function ApplicantsBrief(props: any) {
  return (
      <Card className='applicant-brief-root' elevation={2}>
        <picture>
          <source srcSet={backdrop} type="image/jpeg" />
          <img alt="rocketimg" className='apt-bg' />
        </picture>

        <Box className="svg-drop" sx={{ color: 'background.paper', mask: `url(${shape_svg}) no-repeat center / contain`, WebkitMask: `url(${shape_svg}) no-repeat center / contain` }} />

        <Avatar className='apt-avatar' alt="Avatar" src={""} />

        <Box className='apt-brief'>
          <Typography variant='h4' className='apt-heading'>Candidate</Typography>
          <Typography variant='body1'>Model</Typography>
          <Box display={'flex'}>
              <Typography variant='subtitle2' color={'gray'}>From Delhi, India</Typography>
            </Box>
            <Box display={'flex'}>
              <Typography variant='subtitle2' color={'gray'}>26,&nbsp;</Typography>
              <Typography variant='subtitle2' color={'gray'}>Female</Typography>
            </Box>

          <Stack direction='row' sx={{ my: 0.5 }}>
            <Typography variant='body2'>Applied For:&nbsp;</Typography>
            <Typography variant='body2'>Position</Typography>
          </Stack>
        </Box>
        <Box className='apt-buttons'>
          <ButtonGroup disableElevation fullWidth size='small' variant='text'>
            <Button color='error' sx={{fontSize: 14}}>Reject</Button>
            <Button color='success' sx={{fontSize: 14}}>Shortlist</Button>
          </ButtonGroup>
          </Box>
      </Card >
  );
}
