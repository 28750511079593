import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, InputAdornment, InputBase, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { EventProps, PlaceType } from "../config/props";
import GoogleAutoComplete from "./AutoComplete";
import './css/EventModal.scss';

const basicAmenities = ['Food', 'Travel', 'Security', 'Stay', 'Attire']
const ServiceTypes = [
  { value: 'lumpsum', label: 'lump sum' },
  { value: 'hourly', label: 'per hour' },
  { value: 'daily', label: 'per day' },
  { value: 'weekly', label: 'per week' },
  { value: 'monthly', label: 'per month' },
  { value: 'yearly', label: 'per year' },
];

export default function CreateEvent(props: EventProps) {
  const { pageTitle, eventDetail } = props;

  const { eventid } = useParams();
  const [eid, setEid] = useState<string>('');

  const editorRef = useRef<any>(null);
  const [title, setTitle] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [htmlDesc, setHtmlDesc] = useState<string>('');
  const [locationType, setLocationType] = useState<string>('venue');
  const [locationObject, setLocationObject] = useState<PlaceType | null>(null);

  const [positions, setPositions] = useState<string>('1');
  const [amount, setAmount] = useState<string>('100');
  const [amountType, setAmountType] = useState<string>('lumpsum');
  const [startDate, setStartDate] = useState<string | null>(new Date().toISOString().substring(0, 16));
  const [endDate, setEndDate] = useState<string | null>(new Date().toISOString().substring(0, 16));
  const [applyDate, setApplyDate] = useState<string | null>(new Date().toISOString().substring(0, 16));
  const [amenities, setAmenities] = useState<string[]>([]);

  const addrName = useRef<string>('');
  const addr1 = useRef<string>('');
  const addr2 = useRef<string>('');
  const addrCity = useRef<string>('');
  const addrState = useRef<string>('');
  const addrPincode = useRef<string>('');
  const addrCc = useRef<string>('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    // getAllEvents().then((eves) =>{
    //   console.log(eves);
    // })
    //[] TODO: set values of fields based on props passed, if nothing, create new event else update with eventid
  }, [])


  function handleCreateEvent() {
    // [] TODO: Get All the values and pass it to API with status as live
    // console.log(title, summary, htmlDesc, positions, amenities, amount, startDate, endDate, applyDate, amountType)
  };

  function handleDraftEvent() {
    // [] TODO: Get All the values and pass it to API with status as draft
  };

  const manualAddressFields = (
    <>
      <Stack spacing={2} sx={{ my: 2 }}>
        <TextField required fullWidth className="addressnm" variant="outlined" label="Venue Name" inputRef={addrName} size={"small"} disabled={locationObject ? true : false} />
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }}>
          <TextField required fullWidth className="addressl1" variant="outlined" label="Address 1" inputRef={addr1} size={"small"} disabled={locationObject ? true : false} />
          <TextField required fullWidth className="addressl2" variant="outlined" label="Address 2" inputRef={addr2} size={"small"} disabled={locationObject ? true : false} />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }}>
          <TextField required fullWidth className="addressct" variant="outlined" label="City" inputRef={addrCity} size={"small"} disabled={locationObject ? true : false} />
          <TextField required fullWidth className="addressst" variant="outlined" label="State" inputRef={addrState} size={"small"} disabled={locationObject ? true : false} />
          <TextField required fullWidth className="addresspc" variant="outlined" label="Area Code" inputRef={addrPincode} size={"small"} disabled={locationObject ? true : false} />
          <TextField required fullWidth className="addresscc" variant="outlined" label="Country" inputRef={addrCc} size={"small"} disabled={locationObject ? true : false} />
        </Stack>
      </Stack>
    </>
  )

  return (
    <React.Fragment>
      <Dialog
        className="popup-root"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'lg'}
        open={props.openModal}
        onClose={props.onCloseModal}
        scroll={'paper'}
      >
        <DialogTitle >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography className="title">Create Event</Typography>
            <IconButton
              sx={{ color: 'gray', width: '2.5em', height: '2.5em' }}
              onClick={props.onCloseModal}
            ><CloseIcon fontSize="large" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers className='content'>
          <Typography className="detail-title">Title</Typography>
          <InputBase
            className="detail-input"
            placeholder="Enter your event title here. (100 characters max)"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            endAdornment={
              title ?
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear field"
                    onClick={() => setTitle('')}
                    edge="end"
                    sx={{ color: 'gray' }}
                  ><CancelIcon />
                  </IconButton>
                </InputAdornment>
                : null
            }
          />
          <Typography color={"error"} fontFamily={"inherit"} sx={{ mt: -3, mb: 1 }}>&nbsp;{title.length > 100 ? "Length exceeds current limit of 100 characters" : null}</Typography>

          <Typography className="detail-title">Summary</Typography>
          <InputBase className="detail-input" placeholder="(Optional) Enter a short summary about the event. (150 characters max)"
            value={summary}
            onChange={(event) => setSummary(event.target.value)}
          />
          <Typography color={"error"} fontFamily={"inherit"} sx={{ mt: -3, mb: 1 }}>&nbsp;{summary.length > 150 ? "Length exceeds current limit of 150 characters" : null}</Typography>

          <Typography className="detail-title">Description</Typography>
          <Editor
            id="event-description"
            apiKey='bo4b2mm45u48tprcc6gj04l5mo2c55dzxjrfijed54fbtzj4'
            init={{
              height: 200,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              toolbar_mode: 'sliding',
              content_style: 'body { font-family:"Poppins",Helvetica,Arial,sans-serif; font-size:14px }'
            }}
            onInit={(evt, editor) => editorRef.current = editor}
            disabled={false}
            value={htmlDesc}
            onEditorChange={(newValue, editor) => setHtmlDesc(newValue)}
          />
          <Typography className="detail-title">Location</Typography>
          <Stack direction={'row'} spacing={2} className="location-chips">
            <Chip label="Remote / Not Yet Decided" variant={locationType === "remote" ? "filled" : "outlined"} color={'primary'} onClick={() => setLocationType('remote')} />
            <Chip label="Venue" variant={locationType === "venue" ? "filled" : "outlined"} color={'primary'} onClick={() => setLocationType('venue')} />
          </Stack>

          {locationType === 'venue' ?
            <div>
              <GoogleAutoComplete setSelectedParent={setLocationObject} />
              <Divider variant="middle" sx={{ color: '#505add', fontFamily: 'inherit' }}>or</Divider>
              {!locationObject ? manualAddressFields : null}
            </div>
            :
            null
          }
          <br />
          <Typography className="detail-title">Timings</Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} className="event-dates">
            <Box>
              <Typography sx={{ fontFamily: 'inherit' }}>Start Date & Time</Typography>
              {/* <DateTimePicker
                sx={{ fontFamily: 'inherit' }}
                inputRef={startDate}
                format={"DD/MM/YYYY    hh:mm A"}
                defaultValue={dayjs().add(1, 'd')}
                onChange={(val, ctx) => console.log(val, ctx)}
                disablePast
                closeOnSelect
              /> */}
              <TextField
                className="event-st-date"
                type="datetime-local"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Box>
            <Box>
              <Typography sx={{ fontFamily: 'inherit' }}>End Date & Time</Typography>
              {/* <DateTimePicker
                sx={{ fontFamily: 'inherit' }}
                className="event-st-date"
                inputRef={endDate}
                format={"DD/MM/YYYY    hh:mm A"}
                defaultValue={dayjs().add(7, 'd')}
                onChange={(val, ctx) => console.log(val, ctx)}
                disablePast
                closeOnSelect
              /> */}
              <TextField
                className="event-ed-date"
                type="datetime-local"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </Box>
          </Stack>

          <Typography className="detail-title">Positions</Typography>
          <InputBase
            className="detail-input"
            placeholder="Number of Positions"
            inputMode="numeric"
            type="number"
            value={positions}
            onChange={(event) => setPositions(event.target.value)}
          />

          <Typography className="detail-title">Remuneration</Typography>
          <Stack direction={'row'} spacing={2}>
            <InputBase
              className="detail-input"
              type="number"
              inputMode="numeric"
              placeholder="Amount to be paid"
              startAdornment={
                <InputAdornment position="start">
                  <CurrencyRupeeIcon />
                </InputAdornment>
              }
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
            />

            <Select
              className="amount-type-dropdown"
              value={amountType}
              onChange={(event) => setAmountType(event.target.value)}
            >
              {ServiceTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <Typography sx={{ fontFamily: 'inherit' }} className="detail-title">Apply Before</Typography>
          {/* <DateTimePicker
            sx={{ fontFamily: 'inherit', mb: 2}}
            inputRef={applyDate}
            format={"DD/MM/YYYY    hh:mm A"}
            defaultValue={dayjs().add(1, 'd')}
            onChange={(val, ctx) => console.log(val, ctx)}
            disablePast
            closeOnSelect
          /> */}
          <TextField
            className="event-apl-date"
            type="datetime-local"
            value={applyDate}
            onChange={(event) => setApplyDate(event.target.value)}
          />

          <Typography sx={{ fontFamily: 'inherit' }} className="detail-title">Amenities</Typography>
          <Autocomplete
            multiple
            freeSolo
            className="amenities"
            options={basicAmenities}
            value={amenities}
            onChange={(event, values) => setAmenities(values)}
            renderTags={(value, getTagProps) =>
              value.map((option: string, index: number) => (<Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Amenities"
              />
            )}
          />
        </DialogContent>
        <DialogActions className="action-buttons">
          <Button variant="outlined" className="button" onClick={handleDraftEvent}>Save Draft</Button>
          <Button variant="contained" className="button" onClick={handleCreateEvent}>Publish Event</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
