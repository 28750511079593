import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ExtensionIcon from '@mui/icons-material/Extension';
import ForumIcon from '@mui/icons-material/Forum';
import StreetviewIcon from '@mui/icons-material/Streetview';
import { Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FrostedNavigationBar from '../components/DefaultAppBar';
import EventsFooter from '../components/Footer';
import { ParticlesBackground } from '../components/Particles';
import { CustomizedInputBase } from '../components/StyledComponents';
import { setSection } from '../slices/appSlice';
import { useAppDispatch, useAppSelector } from '../slices/hooks';
import AnalyticsImage from './../assets/analytics.webp';
import ChecklistImage from './../assets/checklist.webp';
import CommunicationImage from './../assets/communication.webp';
import CommunityImage from './../assets/community.webp';
import Friends from './../assets/friends.webp';
import PlanesImg from './../assets/planes.webp';
import RotorImage from './../assets/rotor1.webp';
import './css/LandingPage.scss';


export default function LandingPage() {

  const dispatch = useAppDispatch();
  // const taskOrder = useAppSelector(state => getTaskOrder(state, props.board.id))
  const selectedSection = useAppSelector(state => state.appState.section)


  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>('');

  const temp_btn = (
    <Button key='temp_btn'
      variant='contained'
      onClick={() => navigate("/auth", { state: {} })}
      sx={{
        color: 'inherit',
        mx: 2,
        borderRadius: 10,
        backgroundImage: '-webkit-linear-gradient(120deg, #505add 10%, #dd1177 100%) !important;'
      }}>
      Get Started
    </Button>
  )

  function scrollToSection(name: string) {
    if (name) {
      name = name.replaceAll(' ', '.');
      const container = document.querySelector(`section.${name}`);
      container?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      });
    }
  }

  useEffect(() => {
    scrollToSection(selectedSection);
  }, [selectedSection])


  return (
    <React.Fragment>
      <FrostedNavigationBar btn={temp_btn} disabled />
      <Box component={'section'} className='home-root'>
        <Container maxWidth='xl' disableGutters sx={{ overflow: 'hidden' }}>
          <Stack direction='row' alignItems='center'>
            <Box sx={{ p: { xs: 2, lg: 5 }, mr: { xs: 0, lg: 5 } }}>
              <Typography variant='h2' className='home-heading'>Find your crew,<br /> grow your business.</Typography>
              <Typography variant='h6' className='home-subtitle'>Meet inspiring professionals, discover reliable service providers and crew members who reflect your organization's goals perfectly without any extra efforts. <i>Exactly how you want it.</i></Typography>
              <Button variant='outlined' className='gs-btn' onClick={() => dispatch(setSection('about-root'))}>Know More</Button>
            </Box>
            <Box className='img-container' sx={{ display: { xs: 'none', lg: 'block' } }}>
              <picture>
                <source srcSet={Friends} type="image/webp" />
                <img alt="target_audiance" className='custom-img' loading="lazy"/>
              </picture>
            </Box>
          </Stack>
        </Container>
      </Box>

      <Box component={'section'} className='about-root'>
        <Container maxWidth='xl' disableGutters sx={{ overflow: 'hidden' }}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 5, md: 10 }}>
            <Typography variant='h4' className='about-heading' width={{ xs: '100%', md: '40%' }}>About Our App</Typography>
            <Typography variant='subtitle1' className='about-content'>
              leadmycrew is an evolving event management platform. It has been designed specifically to cut down costs and time in finding the right people for you.<br /><br />
              If you are a <b>freelancer</b>, you can apply for different positions such as models, artists, coordinators, managers, and many more...<br /><br />
              If you are a <b>service provider</b>, you can find potential leads to work for and deliver some major projects for many popular brands.<br />
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Box component={'section'} className='how-it-works-root'>
        <Box className='img-container' sx={{ display: { xs: 'none', lg: 'block' } }}>
          <picture>
            <source srcSet={RotorImage} type="image/webp" />
            <img alt="rotor_image" className='rotor' loading="lazy"/>
          </picture>
        </Box>
        <Box className='img-container' sx={{ display: { xs: 'none', lg: 'block' } }}>
          <picture>
            <source srcSet={PlanesImg} type="image/webp" />
            <img alt="mailer_image" className='planes' loading="lazy"/>
          </picture>
        </Box>
        <Container maxWidth='xl' disableGutters sx={{ overflow: 'hidden' }}>
          <Typography variant='h3' className='step-heading'>How it Works</Typography>
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={8}>
            <Box className='step-root'>
              <Typography> Step 1</Typography>
              <Typography variant='h6' className='step'>Sign Up / Login</Typography>
              <Typography variant='subtitle1' className='step-description'>Create your organisation profile on the platform. Post about the services provided by the organization or any urgent requirements for a crew.</Typography>
            </Box>
            <Box className='step-root' pt={{ xs: '0em', lg: '5em' }}>
              <Typography> Step 2</Typography>
              <Typography variant='h6' className='step'>Shortlist Qualified Personnels</Typography>
              <Typography variant='subtitle1' className='step-description'>Hire professionals hassle-free on contract basis starting from a few hours. Meet Industry's best people with your services as well.</Typography>
            </Box>
            <Box className='step-root' pt={{ xs: '0em', lg: '10em' }}>
              <Typography> Step 3</Typography>
              <Typography variant='h6' className='step'>Increase Exposure</Typography>
              <Typography variant='subtitle1' className='step-description'>Increase both your portfolio and profits by our D2C platform. Volunteer for best events available worldwide.</Typography>
            </Box>
          </Stack>
        </Container>
      </Box>

      <Box component={'section'} className='ethics-root' id='ethics'>
        <Container maxWidth='xl' disableGutters sx={{ overflow: 'hidden' }}>
          <Typography variant='h3' className="ethics-heading">Innovation, Quality, and Integrity.</Typography>
          <Typography variant='body1' className="ethics-body">Find the ideal work with leadmycrew and start a career in event or hospitality industry. Get a chance to work with top companies in India. Build a career in modelling, acting, makeup artist, and many other domains right from day one and get paid for what you love to do on daily basis. Showcase your portfolio and become the star of the city. We are motivated to serve you with quality that also displays our innovative ideas to steadily grow in the exhibition industry. Building a close relationship with our clients, we promise to fulfil their requirements by setting new standards for ourselves.</Typography>
          <Typography variant='body1' className="ethics-body"><i>Our guidelines support a safe and open environment for everyone. We take violations very seriously, and encourage you to report abusive behavior.</i></Typography>
          {/* <Typography variant='body1' className="ethics-body">leadmycrew is an evolving startup and is currently in development phase. leadmycrew will provide it's subscription free of cost for the first year after it's launch. The subscription plans are in preview and will be disclosed soon. If you want to be a beta tester, you can reach out to our development team at *email* Please let us know if you like the app.✌️</Typography>
            <Typography variant='body1' className="ethics-body">Subscribe to our newsletter to recieve exclusive offers and a chance to be a beta user of our latest application. Get all the latest news on our products and activities.</Typography>
          */}
          <br />
          <br />
        </Container>
        <Divider />
      </Box>

      <Box component={'section'} className='features-root'>
        <Container maxWidth='xl' disableGutters sx={{ overflow: 'hidden' }}>
          <Typography variant='h4' className='features-title'>What features we serve for you</Typography>
          <Grid container rowSpacing={4} columnSpacing={{ sm: 2, md: 10 }}>
            <Grid item sm={12} md={6}>
              <Box className='img-container' sx={{ display: { xs: 'none', lg: 'block' } }}>
                <picture>
                  <source srcSet={CommunityImage} type="image/webp" />
                  <img alt="community" className='feature-img' loading="lazy"/>
                </picture>
              </Box>
              <Stack direction='row' className='features-heading'>
                <ExtensionIcon />
                <Typography variant='h5' ml={2} fontSize={'20px'} fontFamily={'inherit'}>Build Commnuniy with Ease</Typography>
              </Stack>
              <Typography variant='body2' className='features-sub-heading'>Connect and Collaborate with professionals, celebrities, or like minded people and get good exposure in the industry. Empower event planners by working with MSMEs. Attend Meetups, Workshops, Events, Roadshows, etx.</Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Box className='img-container' sx={{ display: { xs: 'none', lg: 'block' } }}>
                <picture>
                  <source srcSet={CommunicationImage} type="image/webp" />
                  <img alt="communication" className='feature-img' loading="lazy"/>
                </picture>
              </Box>
              <Stack direction='row' className='features-heading'>
                <ForumIcon />
                <Typography variant='h5' ml={2} fontSize={'20px'} fontFamily={'inherit'}>Keep Track of Communications</Typography>
              </Stack>
              <Typography variant='body2' className='features-sub-heading'>Revolutionize your hiring process with us, track event progess and the communication between teams and management. Keep track of your contracts and your portfolio.</Typography>

            </Grid>
            <Grid item sm={12} md={6}>
              <Box className='img-container' sx={{ display: { xs: 'none', lg: 'block' } }}>
                <picture>
                  <source srcSet={ChecklistImage} type="image/webp" />
                  <img alt="checklist" className='feature-img' loading="lazy"/>
                </picture>
              </Box>
              <Stack direction='row' className='features-heading'>
                <StreetviewIcon />
                <Typography variant='h5' ml={2} fontSize={'20px'} fontFamily={'inherit'}>Customise your Checklists</Typography>
              </Stack>
              <Typography variant='body2' className='features-sub-heading'>Save your time with our default checklist of amenities and event planning operations or create custom checklist to micro manage the tasks with the best in class productivity tools.</Typography>

            </Grid>
            <Grid item sm={12} md={6}>
              <Box className='img-container' sx={{ display: { xs: 'none', lg: 'block' } }}>
                <picture>
                  <source srcSet={AnalyticsImage} type="image/webp" />
                  <img alt="analytics" className='feature-img' loading="lazy"/>
                </picture>
              </Box>
              <Stack direction='row' className='features-heading'>
                <DonutSmallIcon />
                <Typography variant='h5' ml={2} fontSize={'20px'} fontFamily={'inherit'}>Accurate Insights and Analytics</Typography>
              </Stack>
              <Typography variant='body2' className='features-sub-heading'>See performances of your colleagues. Look into the organizations ratings and feedbacks provided by the volunteers. Smart crews use leadmycrew.</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth='xl' disableGutters sx={{ overflow: 'hidden', display:'flex', justifyContent:'center'}}>
        <Paper variant='outlined' className='newsletter-root' component={'section'}>
          <Typography variant='h3' className='newsletter-title'>Stay In Touch!</Typography>
          <Typography variant='body1' className="newsletter-body" paragraph fontSize='20px'>Subscribe to our newsletter to recieve exclusive offers of our application and all the latest news on our products and activities.</Typography>
          <Typography variant='body1' className="newsletter-body" paragraph fontSize='14px'><i>leadmycrew is an evolving startup and is currently in development phase. leadmycrew will provide it's subscription free of cost for the first year after it's launch. The subscription plans are in preview and will be disclosed soon. If you want to be a beta tester, you can reach out to us in the form below. Please let us know if you like the app.✌️</i></Typography>
          <CustomizedInputBase value={email} setValue={setEmail} successMsg={'Successfully Subscribed'} errMsg={'Failed to subscribe'} />
        </Paper>
      </Container>

      <Box sx={{ position: 'relative', width: '100%' }}>
        <ParticlesBackground />
        <EventsFooter />
      </Box>

    </React.Fragment>
  )
}
