import React, { Fragment, useState } from 'react';
import { Box, Button, InputAdornment, MenuItem, Pagination, Select, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import CustomNavigationBar from '../components/CustomAppBar';
import Input from '@mui/material/Input';
import WorkIcon from '@mui/icons-material/Work';
import Rocket from './../assets/rocket.webp';
import PlaceIcon from '@mui/icons-material/Place';
import EventBrief from '../components/EventBrief';
import './css/EventsHomepage.scss';

// sx={{backgroundImage: `url(${job_banner})`


export default function EventsHomepage() {

  const [selectedTab, setSelectedTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchCompany, setSearchCompany] = useState("");


  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  // useEffect(() => {
  //   const loggedInUser = localStorage.getItem("user");
  //   if (loggedInUser) {
  //     const foundUser = JSON.parse(loggedInUser);
  //     setUser(foundUser);
  //   }
  // }, []);

  return (
    <Fragment>
      <CustomNavigationBar />
      <Box className='event-home-root'>
        <Container disableGutters maxWidth='xl'>
          <Box className='box-root'>
            <Box sx={{ width: { xs: '100%', md: '60%' } }}>
              <Typography variant="h3" align='left' className='title-text'>Reach millions of people for the right job.</Typography>
              <br />
              <Typography variant="h5" align='left' className='subtitle-text'>Post your requirements today for free.</Typography>
              <br />
              <Button variant="contained" className='post-evnt-btn'>Post a requirement</Button>
            </Box>
            <Box className='img-container' sx={{ display: { xs: 'none', md: 'block' } }}>
              <picture>
                <source srcSet={Rocket} type="image/webp" />
                <img alt="rocketimg" style={{ width: '20em' }} />
              </picture>
            </Box>
          </Box>

          <Box sx={{ px: { xs: '2em', md: '5em', lg: '10em' }, pt: { xs: '2em', md: '4em' } }} className="search-container">
            <Typography variant="h4" className='title-text'>Recent Openings</Typography>
            <Stack
              className='search-tab'
              direction={{ sm: 'column', md: 'row' }}
              spacing={{ xs: 2, md: 4 }}
            >
              <Input
                id="search-job-profession"
                placeholder="eg. Makeup Artist, etc."
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                autoComplete="name"
                disableUnderline
                startAdornment={
                  <InputAdornment position="start">
                    <WorkIcon />
                  </InputAdornment>
                }
              />
              <Select
                id="search-job-category"
                disableUnderline
                variant='filled'
                value={searchCategory}
                onChange={(event) => setSearchCategory(event.target.value)}
                displayEmpty
                SelectDisplayProps={{ style: { padding: '0.5em' } }}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="a">Internship</MenuItem>
                <MenuItem value="b">Freelance</MenuItem>
                <MenuItem value="d">Part Time</MenuItem>
                <MenuItem value="e">Full Time</MenuItem>
              </Select>
              <Input
                id="search-job-location"
                placeholder="eg. Delhi"
                value={searchLocation}
                onChange={(event) => setSearchLocation(event.target.value)}
                autoComplete="state"
                disableUnderline
                startAdornment={
                  <InputAdornment position="start">
                    <PlaceIcon />
                  </InputAdornment>
                }
              />
              <Button variant="contained" disableElevation className='search-button'>Search</Button>
            </Stack>
          </Box>

          <Stack spacing={2} sx={{ px: { xs: '2em', md: '5em', lg: '10em' }, py: '1em' }}>
            <EventBrief />
            <EventBrief />
            <EventBrief />
            <EventBrief />
            <EventBrief />
            <EventBrief />
            <EventBrief />
            <EventBrief />
            <EventBrief />
          </Stack>
          <Pagination count={10} shape="rounded" size="large" className='event-paginator' />
        </Container>
      </Box>
    </Fragment>
  )
}
