import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { PlaceType } from '../config/props';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { TextField } from '@mui/material';



function getInputComponent(params: any) {
  return (
    <TextField {...params} placeholder="Enter a location" />
  );
}

function getOptionListItem(props: any, optionItem: PlaceType) {
  const matches = optionItem.structured_formatting.main_text_matched_substrings || [];
  // add-on library to add highlight matching text in the options.
  const parts = parse(optionItem.structured_formatting.main_text, matches.map((match: any) => [match.offset, match.offset + match.length]));

  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item sx={{ display: 'flex' }}>
          <LocationOnIcon sx={{ color: 'text.secondary' }} />
        </Grid>
        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
          {parts.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
            >
              {part.text}
            </Box>
          ))}
          <Typography variant="body2" color="text.secondary">
            {optionItem.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
}

export default function GoogleAutoComplete(props: {setSelectedParent: (arg0: PlaceType | null) => void}) {
  const [inputValue, setInputValue] = useState<string>('');

  const [options, setOptions] = useState<PlaceType[]>([]);
  const [selectedValue, setSelectedValue] = useState<PlaceType | null>(null);

  const scriptLoaded = useRef<boolean>(false);
  const autocompleteService = useRef<any>(null);
  const autocompleteToken = useRef<any>(null);



  const fetch = useMemo(() => debounce((
    request: { input: string, sessionToken: object }, callback: (results?: PlaceType[]) => void) => {
      // if (request.sessionToken) {
      //   autocompleteService.current.getPlacePredictions(request, callback);
      // }

      callback([])
    }, 1000),
  []);


  useLayoutEffect(() => {
    // load places script from google lib defined in index.html
    if (typeof window !== 'undefined' && window.google && !scriptLoaded.current) {
      window.google.maps.importLibrary("places");
      scriptLoaded.current = true
    }
  }, [])


  useEffect(() => {
    // mount autocompleteservice if not mounted.
    if (!autocompleteService.current && window.google.maps.places) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      autocompleteToken.current = new google.maps.places.AutocompleteSessionToken();
    }

    // in case component fails to load / loading slowly, show empty list in starting.
    if (!autocompleteService.current || inputValue === '') {
      setOptions(selectedValue ? [selectedValue] : []);
      return undefined;
    }

    fetch({ input: inputValue, sessionToken: autocompleteToken.current }, (results?: PlaceType[]) => {
      setOptions(results ?? []);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);


  // google.maps.places.PlacesService.getDetails()
  // this should be called while saving the page

  return (
    <Autocomplete
      id="location-input"
      renderInput={(params) => getInputComponent(params)}
      options={options}
      getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
      renderOption={(props, option) => getOptionListItem(props, option)}
      noOptionsText="No Locations"
      value={selectedValue}
      autoComplete
      autoSelect
      filterSelectedOptions
      includeInputInList
      onChange={(event, newValue, reason) => {
        setSelectedValue(newValue);
        props.setSelectedParent(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
      fullWidth
    />
  );
}
